/**
 * 点击事件防重
 */
export default {
  beforeMount(el, binding, vnode) {
    el.addEventListener('click', () => {
      console.log(111);
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  }
}
