let viewgird = [
    {
        path: '/Sys_Log',
        name: 'sys_Log',
        component: () => import('@/views/system/Sys_Log.vue')
    },
    {
        path: '/Sys_User',
        name: 'Sys_User',
        component: () => import('@/views/system/Sys_User.vue')
    },
    {
        path: '/permission',
        name: 'permission',
        component: () => import('@/views/system/Permission.vue')
    },

    {
        path: '/Sys_Dictionary',
        name: 'Sys_Dictionary',
        component: () => import('@/views/system/Sys_Dictionary.vue')
    },
    {
        path: '/Sys_Role',
        name: 'Sys_Role',
        component: () => import('@/views/system/Sys_Role.vue')
    }, {
        path: '/Sys_Role1',
        name: 'Sys_Role1',
        component: () => import('@/views/system/Sys_Role1.vue')
    }, {
        path: '/Sys_DictionaryList',
        name: 'Sys_DictionaryList',
        component: () => import('@/views/system/Sys_DictionaryList.vue')
    }, {
        path: '/SellOrder',
        name: 'SellOrder',
        component: () => import('@/views/order/SellOrder.vue')
    }, {
        path: '/SellOrder2',
        name: 'SellOrder2',
        component: () => import('@/views/order/SellOrder2.vue')
    }, {
        path: '/SellOrder3',
        name: 'SellOrder3',
        component: () => import('@/views/order/SellOrder3.vue')
    }, {
        path: '/vSellOrderImg',
        name: 'vSellOrderImg',
        component: () => import('@/views/order/vSellOrderImg.vue')
    },
    {
        path: '/App_Appointment',
        name: 'App_Appointment',
        component: () => import('@/views/order/App_Appointment.vue')
    },
    {
        path: '/App_TransactionAvgPrice',
        name: 'App_TransactionAvgPrice',
        component: () => import('@/views/appmanager/App_TransactionAvgPrice.vue')
    }, {
        path: '/App_Expert',
        name: 'App_Expert',
        component: () => import('@/views/appmanager/App_Expert.vue')
    }, {
        path: '/App_Expert2',
        name: 'App_Expert2',
        component: () => import('@/views/appmanager/App_Expert2.vue')
    }, {
        path: '/App_Transaction',
        name: 'App_Transaction',
        component: () => import('@/views/appmanager/App_Transaction.vue')
    }, {
        path: '/App_Transaction2',
        name: 'App_Transaction2',
        component: () => import('@/views/appmanager/App_Transaction2.vue')
    }, {
        path: '/App_ReportPrice',
        name: 'App_ReportPrice',
        component: () => import('@/views/appmanager/App_ReportPrice.vue')
    }, {
        path: '/App_News',
        name: 'App_News',
        component: () => import('@/views/appmanager/App_News.vue')
    }, {
        path: '/App_NewsEditor',
        name: 'App_NewsEditor',
        component: () => import('@/views/appmanager/App_NewsEditor.vue')
    }, {
        path: '/T_business_module',
        name: 'T_business_module',
        component: () => import('@/views/kpimanager/baseinfo/T_business_module.vue')
    }, {
        path: '/T_dept',
        name: '部门配置',
        component: () => import('@/views/kpimanager/baseinfo/T_dept.vue')
    }, {
        path: '/Baseinfor',
        name: 'Baseinfor',
        component: () => import('@/views/kpimanager/baseinfo/Baseinfor.vue')
    }, {
        path: '/PublicData',
        name: 'PublicData',
        component: () => import('@/views/kpimanager/baseinfo/PublicData.vue')
    }, {
        path: '/Staff_percentage',
        name: 'Staff_percentage',
        component: () => import('@/views/kpimanager/department/Staff_percentage.vue')
    }, {
        path: '/D_list',
        name: 'D_list',
        component: () => import('@/views/kpimanager/department/D_list.vue')
    }, {
        path: '/S_manager',
        name: 'S_manager',
        component: () => import('@/views/kpimanager/department/S_manager.vue')
    }, {
        path: '/Business_Line',
        name: 'Business_Line',
        component: () => import('@/views/kpimanager/business/Business_Line.vue')
    }, {
        path: '/Business_Line_module',
        name: 'Business_Line_module',
        component: () => import('@/views/kpimanager/business/Business_Line_module.vue')
    }, {
        path: '/Business_Line_Detail',
        name: 'Business_Line_Detail',
        component: () => import('@/views/kpimanager/business/Business_Line_Detail.vue')
    }, {
        path: '/T_business_user_month_kpi',
        name: 'T_business_user_month_kpi',
        component: () => import('@/views/kpimanager/kpi/T_business_user_month_kpi.vue')
    },
    {
        path: '/monthTargetDecompose',
        name: '季度目标分解',
        component: () => import('@/views/kpimanager/kpi/monthTargetDecompose.vue')
    }, {
        path: '/monthComplete',
        name: '月度完成值',
        component: () => import('@/views/kpimanager/kpi/monthComplete.vue')
    }, {
        path: '/monthDetailsList',
        name: '月度详情报表',
        component: () => import('@/views/kpimanager/kpi/monthDetailsList.vue')
    }, {
        path: '/monthReview',
        name: '月度审核',
        component: () => import('@/views/kpimanager/kpi/monthReview.vue')
    }, {
        path: '/standingBook',
        name: '项目台账',
        component: () => import('@/views/kpimanager/kpi/standingBook.vue'),
        meta: {
            keepAlive: false
        },
    }, {
        path: '/standingBookAdd',
        name: 'standingBookAdd',
        component: () => import('@/views/kpimanager/kpi/standingBookAdd.vue')
    }, {
        path: '/reportBonus',
        name: '奖金分配报表',
        component: () => import('@/views/bonusDistribution/reportBonus.vue')
    },{
        path: '/workLineBonus',
        name: '业务线奖金分配',
        component: () => import('@/views/bonusDistribution/workLineBonus.vue')
    }, {
        path: '/deptBonus',
        name: '部门奖金分配',
        component: () => import('@/views/bonusDistribution/deptBonus.vue')
    }, {
        path: '/staffBonus',
        name: '员工奖金分配',
        component: () => import('@/views/bonusDistribution/staffBonus.vue')
    }, {
        path: '/monthlyReport',
        name: '月度报表',
        component: () => import('@/views/progressReport/monthlyReport.vue')
    }, {
        path: '/quarterlyReport',
        name: '季度报表',
        component: () => import('@/views/progressReport/quarterlyReport.vue')
    }, {
        path: '/yearReport',
        name: '年度报表',
        component: () => import('@/views/progressReport/yearReport.vue')
    }, {
        path: '/people',
        name: '人力成本核算',
        component: () => import('@/views/costCalculation/people.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/money',
        name: '财务成本核算',
        component: () => import('@/views/costCalculation/money.vue'),
        meta: {
            keepAlive: false
        },

    },
    {
        path: '/reviewProgress',
        name: '审核进度表',
        component: () => import('@/views/reviewProgress/reviewProgress.vue'),
        meta: {
            keepAlive: false
        },

    }
]

export default viewgird
