import {createApp} from 'vue'
import App from './App.vue'
// import App from './views/bonusDistribution/staffBonus.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import base, {getYearBetween, moneyFormat} from './uitils/common'
import http from './api/http'
import 'dayjs/locale/zh-cn'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import '@/assets/css/index.less' // global css
import permission from './api/permission'
import viewgird from './components/basic/ViewGrid';
import {preventReClick} from './directive/index'
import moment from "moment";
const app = createApp(App);
app.config.globalProperties.base = base;
app.config.globalProperties.$getYearBetween = getYearBetween;
app.config.globalProperties.$moneyFormat = moneyFormat;
app.config.globalProperties.http = http;
app.config.globalProperties.$oss = process.env.VUE_APP_OSS;
app.config.globalProperties.$tabs = {};
app.config.globalProperties.permission = permission;
app.config.globalProperties.$moment = moment;
app.directive('preventReClick', preventReClick)
app.use(store)
    .use(ElementPlus, {size: 'medium'})
    .use(router)
    .use(viewgird)
    .mount('#app');
app.config.globalProperties.$Message = app.config.globalProperties.$message;
